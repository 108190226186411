<template>
  <LkAssistanceProvideCompany mode="editProfile"></LkAssistanceProvideCompany>
</template>

<script>
import LkAssistanceProvideCompany from '@component/Module/EditProfile/LkAssistanceProvideCompany.vue';

export default {
  name: 'EditProfile',
  components: {
    LkAssistanceProvideCompany,
  },
};
</script>